.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.dark{
  --text-color: var(--black);
  --bg-color: var(--ice);
}

.wrapper{
  background: var(--bg-color);
  padding: 0 !important;
}

.container {
  --size-img: 700px;
  position: relative;
  min-height: var(--size-img);
  margin: var(--spacing-24) !important;
  grid-column: 1 / -1 !important;
}

.container img{
  border-radius: var(--radius-l);
}

@media (max-width: 1024px) {
  .container{
    --size-img: 440px;
    margin: var(--spacing-16) !important;
  }
}